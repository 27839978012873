import React, { Component } from 'react';

import { AppStateContext } from 'src/contexts/app-state-provider.context';
import Layout from 'src/components/layout';
import { TranslatedBlock } from 'src/components/language';

class PatientPage extends Component {
  static contextType = AppStateContext;
  componentDidMount() {
    this.context.setPage('terms-and-conditions');
  }

  render() {
    const title = {
      english: 'Terms and Conditions'
    };

    const showNavbar = this.context.section === 'patient';

    return (
      <Layout title={title} showNavbar={showNavbar} className="patient">
        <section className="container my-5">
          <div className="row">
            <div className="col-12 pb-3">
              <TranslatedBlock language="english">
                <h1 className="ornament ornament-header">Terms and Conditions</h1>
              </TranslatedBlock>
            </div>
            <div className="col-12 pb-3 os-font">
              <TranslatedBlock language="english">
                <p>EFFECTIVE AS OF July 16, 2010</p>
                <p>TERMS OF USE</p>
                <p>
                  PLEASE READ AND REVIEW CAREFULLY THE FOLLOWING TERMS OF USE BEFORE USING THIS WEB SITE. This website
                  was developed as a service provided by Hoffmann-La Roche Limited ("Roche"). By using this website, you
                  agree to these Terms of Use. If you do not agree, please exit and disregard the information contained
                  herein.
                </p>
                <p>
                  <u>Users</u>
                </p>
                <p>
                  The material on this website is intended only for general information and education purposes, and is
                  only for residents of Canada.
                </p>
                <p>
                  <u>Modification of Terms or Website</u>
                </p>
                <p>
                  Roche reserves the right to change these terms or any part of this website from time to time, as it
                  deems to be necessary. You may view the current applicable version of these Terms of Use at any time
                  through a link at the bottom of each page within this website.
                </p>
                <p>
                  <u>Medical Information</u>
                </p>
                <p>
                  This website includes product and medical information intended for educational use only. The
                  information provided on this website is not intended to be comprehensive, nor is it a substitute for
                  professional medical advice and judgment. USERS MUST CONSULT A SUITABLY QUALIFIED HEALTHCARE
                  PROFESSIONAL ON ANY PROBLEM OR MATTER WHICH IS COVERED BY ANY INFORMATION ON THIS WEBSITE BEFORE
                  TAKING ANY DECISION OR ACTION.
                </p>
                <p>
                  <u>Disclaimers</u>
                </p>
                <p>
                  Roche has made an effort to include accurate and up-to-date information on this website. However,
                  since medical science is always in flux, the information on this website may not be up-to-date, and is
                  therefore provided “as is”. Roche makes no warranties or representations of any kind, express or
                  implied, about any content on this website. Furthermore, Roche does not warrant that the functions
                  contained on this website will be uninterrupted or error-free, or that this website or the server that
                  makes it available are free of viruses or other harmful components. Some jurisdictions do not allow
                  the exclusion of implied warranties, so the above exclusion may not apply to you.
                </p>
                <p>
                  All users agree that all access and use of this website and its content is at their own risk. Neither
                  Roche, nor its affiliated companies, nor any other party involved in creating, producing or delivering
                  this website shall be liable in any manner whatsoever for any direct, incidental, consequential,
                  indirect or punitive damages arising out of (a) a user’s access, use or inability to use this website
                  and (b) any errors or omissions in the website’s content.
                </p>
                <p>
                  <u>Third Party Content and Links to Other Websites</u>
                </p>
                <p>
                  This website may contain third party owned content (e.g. articles, data feeds or abstracts) and
                  contains links to other websites maintained by third parties over whom Roche has no control. Such
                  content and links are provided merely as a convenience to users of this website. Roche does not
                  endorse and assumes no responsibility for the content of such websites and makes no representations as
                  to the accuracy or completeness of any information contained on such sites. In particular, Roche does
                  not accept any liability arising out of any allegation that any third party owned content (whether
                  published on this, or any other, website) infringes the intellectual property rights of any person or
                  any liability arising out of any information or opinion contained on such third party website or
                  content.
                </p>
                <p>
                  <u>Intellectual Property</u>
                </p>
                <p>
                  All images and information contained on this website are copyrighted and otherwise proprietary. Unless
                  otherwise expressly noted, nothing on this website may be copied or used except as provided in these
                  Terms of Use or with the prior written consent of Roche.
                </p>
                <p>
                  Roche grants you permission to print individual pages from this website, unless otherwise expressly
                  noted, for your personal, non-commercial use. No other permission is granted to you to print, copy,
                  reproduce, distribute, license, transfer, sell, transmit, upload, download, store, display in public,
                  alter, modify or create derivative works of these materials. This grant of permission is not a
                  transfer of title.
                </p>
                <p>
                  Unless otherwise indicated, all logos, designs, and marks on this website are trademarks or service
                  marks owned or used under license by Roche. All product names printed in capitals or otherwise
                  appropriately marked on this website are trademarks of their respective owners.
                </p>
                <p>
                  <u>Privacy</u>
                </p>
                <p>
                  Please refer to Roche's Privacy Policy for information on how Roche protects personal information
                  about you.
                </p>
                <p>
                  <u>Termination of Access</u>
                </p>
                <p>
                  Roche reserves the right to suspend or terminate your access to all or part of this website, at any
                  time, without notice to you, if it believes, in its sole judgment, that you have breached or may
                  breach any term or condition of this agreement, or for its convenience.
                </p>
              </TranslatedBlock>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default PatientPage;
